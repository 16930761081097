.login-component {
  @include flexbox(column, center, center);
  border: 3px solid black;
  width: 20%;
  margin: 80px auto;
  padding: 1% 0%;

  .input-container {
    @include flexbox(column, center, center);

    .login-button {
      margin-top: 30px;
    }
  }

  .no-account-text {
    margin: 20px 0;
    text-align: center;
    span {
      position: relative;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        top: 3px;
      }
    }
  }

  .login-trouble {
    margin: 20px 0;
    text-decoration: none;
    color: black;
    position: relative;

    &:hover {
      top: -3px;
      border-bottom: 1px solid black;
    }
  }
}
