.password-recovery {
  @include flexbox(column, center, center);
  border: 3px solid black;
  width: 80%;
  margin: 40px auto;
  padding: 1% 0%;
  form {
    @include flexbox(column, center, center);
    h4 {
      text-align: center;
    }
    .input-container {
      @include flexbox(column, center, center);
    }
    .submit-recovery {
      margin: 20px auto;
    }
  }
}
