@import "../../../../../styles/mixins/flexbox.scss";
.saved-sat-rows {
  width: 100%;
  @include flexbox(column, center, center);

  .saved-cfg {
    @include flexbox(row, space-between, center);
    width: 100%;
    border: 1px solid black;
  }
  .recent-configs-select {
    font-size: 1.1em;
  }
}
