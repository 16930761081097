//mixins
@import "./mixins/flexbox.scss";
//ui
@import "./header.scss";
@import "./ui/modal.scss";

//modules
@import "./modules/tableSelect.scss";
@import "./modules/buildTable.scss";
@import "./modules/module.scss";
//inverters
@import "./inverters/inverterSearch.scss";
@import "./inverters/inverterSelect.scss";
//project-creation
@import "./createProject/creationToolbox.scss";
//creation modals
@import "./createProject/creationModals/generalDataModal.scss";

@import "./createProject/creationModals/pvDataModal.scss";
@import "./createProject/creationModals/electricalDataModal.scss";
@import "./createProject/creationModals/projectConfimationModal.scss";

//map
@import "./map/mapContainer.scss";

//components
@import "./splash.scss";
@import "./uploader.scss";
//authentication
@import "./authentication/login.scss";
@import "./authentication/registration.scss";
@import "./authentication/passwordRecovery.scss";
//user
@import "./user/createProject.scss";
@import "./user/projectCard.scss";
@import "./user/projectListItem.scss";
@import "./user/userDashboard.scss";
@import "./user/userTables.scss";

.html {
  font-size: 62.5%;
}
