.land-data-modal {
  position: absolute;
  left: 24%;

  transform: translate(-50%, 0);
  background-color: white;
  border: 3px inset black;
  padding: 1%;
  z-index: 499;
}
