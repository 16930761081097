.uploader {
  @include flexbox(column, center, center);
  width: 28%;
  height: 60vh;
  position: fixed;
  left: 0;
  top: 5%;
  overflow-y: scroll;
  .project-name {
    font-size: 1.5em;
  }
  .file-select {
    font-size: 1.1em;
    margin-left: 100px;
  }
  .drop-zone {
    @include flexbox(row, center, center);
    width: 150px;
    height: 25px;
    padding: 2rem;
    background: transparent;
    border: 2px solid black;
    border-radius: 0.5rem;

    & .dropped-files {
      font-weight: bold;
    }
    .inside-drag-area {
      opacity: 0.7;
    }
  }

  .progessBar {
    height: 1rem;
    width: 0%;
    background-color: rgb(68, 212, 231);
    color: white;
    padding: 2px;
  }

  .submit {
    font-size: 1.5em;
    margin-top: 30px;
  }
}
