.create-project {
  width: 100%;

  .creation-modal {
    position: absolute;
    background-color: white;
    border: 3px inset black;
    padding: 1%;
    box-shadow: 2px 3px 6px #333;
    form {
      // @include flexbox(column, center, center);
      border: 1px solid black;

      margin: 0 auto;

      div {
        // @include flexbox(column, center, center);

        .input-container {
          padding: 1%;
          display: flex;
          flex-direction: column;
          align-items: center;
          label {
            text-align: center;
          }
          // input {
          //   margin: 10px 18px;
          // }
        }
      }
    }
  }
  .main {
    @include flexbox(row, space-between, center);
    width: 100%;
  }
}
