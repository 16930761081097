@import "styles/mixins/flexbox.scss";

.loading-modal {
  @include flexbox(column, flex-start, center);
  background-color: white;
  position: relative;
  z-index: 1400;
  max-height: 54.5rem;
  width: 17.5rem;
  font-size: 1.1em;
  left: 15rem;
  top: 1.5rem;
  padding: 0.25rem;
  border: 0.2rem solid black;
  .status-text {
    margin: 0 auto;
  }
  .land-data {
    @include flexbox(column, flex-start, center);

    width: 100%;
    .title {
      margin: 0.2rem auto;
    }
    .total-area {
      margin: 0.2rem auto;
    }
    .buildable-area {
      margin: 0.2rem auto;
    }
  }
  .electrical-data {
    width: 100%;
    @include flexbox(column, flex-start, center);
    .title {
      margin: 0 auto;
    }
    .subtitle {
      margin: 0 auto;
    }
    .selected-module {
      margin: 0.2rem auto;
      text-align: center;
    }
    .selected-inverter {
      margin: 0.2rem auto;
      text-align: center;
    }
  }
  .sat-row-overview {
    width: 100%;
    @include flexbox(column, flex-start, center);
    .title {
      margin: 0.2rem auto;
    }
    .total-tables {
      margin: 0.2rem auto;
    }
    .power-subtotal {
      margin: 0.2rem auto;
    }
  } //sat row overview

  .download-project-files {
    position: relative;
    margin-bottom: 0.5rem;
    cursor: pointer;
  }
  .close-loading-modal {
    position: relative;
    bottom: 0rem;
    cursor: pointer;
  }
}
