@import "styles/mixins/flexbox.scss";

.pv-layout-modal {
  position: absolute;
  left: 28%;
  top: 14%;
  transform: translate(-50%, 0);
  background-color: white;
  border: 3px inset black;
  padding: 2rem;
  z-index: 299;

  .pv-layout-form {
    padding: 1rem;

    .layout-cfg-driven-inputs {
      border: 1px solid black;
      box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.4);
    }

    .button-container {
      @include flexbox(column, center, center);
      margin-top: 0.5rem;
    }
  }
}
