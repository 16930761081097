.project-general-data-modal {
  @include flexbox(column, center, center);
  position: absolute;
  left: 25%;
  transform: translate(-50%, 0);
  background-color: white;
  border: 3px inset black;
  padding: 1%;
  z-index: 500;
}
