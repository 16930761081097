@import "styles/mixins/flexbox.scss";
.tracker-cfg-form {
  @include flexbox(column, center, center);

  .input-container {
    @include flexbox(column, center, center);
    margin: 0.25rem 0;
    .twop-radio-container {
      width: 100%;
      @include flexbox(row, space-evenly, center);
    }
  }
  .sat-row-inputs {
    border: 1px solid black;
    box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.4);
    padding: 1rem;
  }

  .sat-row-dimensions {
    @include flexbox(column, center, center);
    .edit-dimensions {
      width: 80%;
      margin: 0 auto;
    }
  }

  .save-new-cfg {
    margin: 1rem 0;
  }
}
