@import "styles/mixins/flexbox.scss";
.creation-container {
  @include flexbox(column, center, center);
  position: absolute;
  z-index: 235;
  background-color: white;
  top: 4rem;
}
.edit-tracker-cfgs {
  @include flexbox(column, center, center);
  position: absolute;
  top: 15rem;
  background-color: white;
  border: 2px solid black;
  width: 60%;
  max-width: 70rem;
  min-height: 10rem;
  z-index: 210;
  padding-bottom: 1rem;

  .saved-cfg {
    @include flexbox(row, space-between, center);
    width: 75%;
    max-width: 60rem;

    .tracker-name {
    }
    .tracker-edit-buttons {
      @include flexbox(row, space-between, center);
      width: 7rem;
    }
    .edit-tracker {
      cursor: pointer;
      position: relative;
      &:hover {
        top: 0.15rem;
      }
    }
    .delete-tracker {
      background-color: red;
      color: white;
      cursor: pointer;
      position: relative;
      &:hover {
        top: 0.15rem;
      }
    }
  }
  .create-new {
    margin-top: -0.5rem;
  }

  .close {
    position: relative;
    bottom: 0;
    top: 1rem;
  }
}
