@import "styles/mixins/flexbox.scss";
.tracker-cfg-form {
  @include flexbox(column, center, center);
  border: 2px solid black;
  background-color: white;
  left: 4rem;
  top: -4rem;
  position: absolute;
  .input-container {
    @include flexbox(column, center, center);
  }
  .save-new-cfg {
    margin: 1rem 0;
  }
}
