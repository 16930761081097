.inverter-select {
  width: max-content;

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  height: min-content;
  position: absolute;
  z-index: 200;
  background-color: white;
  top: 150px;
  height: 500px;

  .placeholder-div {
    width: 200px;
  }
  .no-search-text {
    text-align: center;
  }
}
