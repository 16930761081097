@import "../../../styles/mixins/flexbox.scss";
.user-dashboard {
  @include flexbox(column, center, center);

  .create-user-project {
    .create-project-component {
      border: 0.2rem solid black;
      width: 50rem;
      padding: 1rem 2rem;
    }
  }
  .logout {
    margin-top: 3rem;
  }
}
