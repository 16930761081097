@import "styles/mixins/flexbox.scss";
.tracker-layout-modal {
  @include flexbox(column, flex-start, center);
  position: absolute;
  background-color: white;
  border: 2px solid black;
  padding: 1rem;
  left: 33%;
  top: 2%;
  transform: translate(-50%, 0);
  width: 25vw;
  max-height: 85vh;
  overflow-y: scroll;
  z-index: 300;
  .section-heading {
    font-size: 1.1rem;
    text-align: center;
  }

  .create-new-cfg {
    margin: 1rem 0 1rem 0;
  }
}
