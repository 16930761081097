@import "../../../styles/mixins/flexbox.scss";

.structural-data-modal {
  @include flexbox(column, center, center);
  position: absolute;
  left: 30%;
  top: 10%;
  transform: translate(-50%, 0);
  width: 20vw;
  z-index: 300;
  background-color: white;
  border: 3px inset black;
  padding: 1%;
  .tracker-elevation-data {
    @include flexbox(column, center, center);
  }
  .input-container {
    @include flexbox(column, center, center);
  }
}
