@import "../../../styles/mixins/flexbox.scss";

.password-reset-container {
  @include flexbox(column, center, center);
  width: 50%;
  margin: 0 auto;
  border: 1px solid black;
  margin-top: 50px;
  padding: 20px 0;
  .section-heading {
  }
  .reset-form {
    @include flexbox(column, center, center);
  }
  .input-container {
    @include flexbox(column, center, center);
    .reset-input {
    }
  }
  .submit-container {
    .submit {
      margin-top: 20px;
    }
  }
}
