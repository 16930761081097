.individual-module {
  border: 2px solid black;
  width: 90%;
  text-align: center;
  margin-bottom: 10px;
  @include flexbox(column, center, center);

  .module-buttons {
    width: 100%;
    @include flexbox(row, center, center);
    padding: 1%;

    .fa-question-circle {
      color: dodgerblue;
      margin: 5px 20px;
      font-size: 1.3em;
      cursor: pointer;
    }
    //tooltip styles
    .tooltip {
      width: 80px;
      font-size: 0.8em;
      background-color: #555;
      color: #fff;
      text-align: center;
      padding: 5px 0;
      border-radius: 6px;
      position: absolute;
    }
    .info-tip {
      margin-top: -10px;
      margin-left: -120px;
    }
    .add-tip {
      margin-top: -10px;
      margin-left: -18px;
    }

    //tooltip styles

    .fa-plus-square {
      color: green;
      margin: 5px 20px;
      font-size: 1.3em;
      cursor: pointer;
    }
  } //module-buttons
}
