@import "styles/mixins/flexbox.scss";

.module-search {
  @include flexbox(column, center, center);
  .title {
    font-size: 1rem;
  }

  .manufacturer-select {
    width: 10rem;
  }

  .module-select {
    border: 1px solid black;
    background-color: white;
    width: 20rem;
    position: absolute;
    top: 16rem;
    height: 25rem;
    overflow-y: scroll;
  }
}
