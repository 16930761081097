@import "styles/mixins/flexbox.scss";

.area-breakdown-container {
  @include flexbox(column, flex-start, center);
  width: 100%;
  position: relative;
  z-index: 1450;

  .areas-filtered {
    position: absolute;
    margin-top: 2.5rem;
  }

  .array-area-breakdown {
    width: 100%;
    max-height: 16rem;
    margin: 0 auto;

    overflow-y: scroll;
    border: 1px solid black;
    scrollbar-color: black;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 0.5rem;
      height: 1rem;
      margin-top: -1.8rem;
    }
    &::-webkit-scrollbar-track:no-button {
      width: 0.5rem;
      height: 1rem;
      border-radius: 0.5rem;
      background-color: white;
    }
    &::-webkit-scrollbar-button {
      color: black;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: black;
      background-color: black;
    }

    .array-area {
      border-bottom: 1px solid black;
      padding-left: 0.2rem;
      .title {
        font-size: 1.1rem;
        font-weight: bold;
      }
      .stat {
      }
    } //array-area
  } //array area breakdown
  .filter-button {
    margin: 0.5rem;
  }
}
