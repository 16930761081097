@import "../../styles/mixins/flexbox.scss";

.modal-container {
  @include flexbox(column, center, center);
  position: absolute;
  z-index: 400;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: white;
  border: 3px inset black;
  padding: 1%;

  .modal-buttons {
    @include flexbox(row, space-between, center);
    width: 20rem;
    position: relative;
    .delete {
      background-color: red;
      color: white;
      cursor: pointer;
      position: relative;
      &:hover {
        top: 0.2rem;
      }
    }
    .close {
      cursor: pointer;
      position: relative;
      &:hover {
        top: 0.2rem;
      }
    }
  }
}
