.electric-data-modal {
  width: 25%;

  left: 15%;
  right: 0;
  top: 12%;
  z-index: 400;

  .modules-and-inverters {
    @include flexbox(column, space-between, center);
    margin: 10px auto;

    .electrical-data-form {
      @include flexbox(column, center, center);
      width: 50%;
      padding: 2% 0;
      margin-top: 20px;
      .electrical-input-container {
        @include flexbox(column, center, center);
      }
    }
    .table-search {
      width: 50%;
      position: relative;
      @include flexbox(column, center, center);
      .table-title {
        text-align: center;
        font-size: 1em;
      }
      .search-for-tables {
        font-size: 1em;
      }
    }
  }
}
