// @import url(https://api.mapbox.com/mapbox-assembly/mbx/v0.18.0/assembly.min.css);
// @import url(https://api.tiles.mapbox.com/mapbox-gl-js/v1.11.1/mapbox-gl.css);
.map-container {
  width: 100vh;
  height: 100vw;
  border: 4px solid black;
  position: absolute;

  background-color: white;

  padding: 1%;

  @media (max-width: 1400px) {
    left: 60%;
  }
}
