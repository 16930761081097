.results {
  border: 1px solid black;
  width: 100%;
  padding: 0 2%;
  margin: 20px 0;
  cursor: pointer;
  position: relative;
  .tool-text {
    margin: 10px;
    position: relative;
    pointer-events: none;
  }
  &:hover {
    background-color: #ddd;
  }
}
