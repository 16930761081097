@import "../../../../styles/mixins/flexbox.scss";

.project-list-item {
  border: 0.2rem solid black;
  width: 50rem;
  padding: 0.5rem 2rem;
  .project-info-container {
    .project-name {
      font-size: 1.2rem;
    }
    .stat {
      font-size: 1rem;
      width: 75%;
    }
    .electric-data {
      width: 100%;
      @include flexbox(row, space-between, center);
      .stat {
        width: 45%;
        font-size: 0.9rem;
      }
    }
  }
}
