.creation-toolbox {
  border: 4px inset black;
  padding: 0 1%;
  width: max-content;
  margin-left: 50px;
  position: relative;
  z-index: 50;
  background-color: white;

  .tool {
    border: 1px solid black;
    width: 100%;
    padding: 0 2%;
    margin: 20px 0;
    cursor: pointer;
    position: relative;
    .tool-text {
      margin: 10px;
      position: relative;
      pointer-events: none;
    }
    &:hover {
      background-color: #ddd;
    }
  }

  .buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    button {
      margin-top: 15px;
    }
  }
  .buttons-vertical {
    width: 100%;
    @include flexbox(column, space-evenly, center);
    button {
      margin-top: 15px;
    }
  }
}
