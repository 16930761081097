.inverter-search {
  width: 50%;
  @include flexbox(column, center, center);
  position: relative;
  .inverter-title {
    text-align: center;
    font-size: 1em;
  }
  .search-for-inverters {
    font-size: 1em;
  }
}
