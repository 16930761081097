.build-table {
  @include flexbox(column, center, center);
  position: absolute;
  z-index: 200;
  width: 75%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 20%;
  background-color: white;
  border: 2px solid black;
  height: 50vh;
  div {
    width: 100%;
    @include flexbox(row, space-evenly, center);
    flex-wrap: wrap;
  }
  .pv-array-layout-on-my-table {
    width: 80%;
  }
}
