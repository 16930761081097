.splash-page {
  @include flexbox(column, center, center);
  width: 80%;
  margin: 0 auto;

  .splash-text {
    width: 75%;
    margin: 0 auto;
    text-align: center;
  }
}
