.user-registration {
  @include flexbox(column, center, center);
  border: 3px solid black;
  width: 20%;
  margin: 40px auto;
  padding: 1% 0%;

  .input-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .registration-button {
      margin-top: 30px;
    }
  }
  .have-account-text {
    span {
      position: relative;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        top: 3px;
      }
    }
  }
}
